import React from 'react'
import {Button} from './Button'
import {useDocumentState} from '../state/document.state'
import sanitize from 'sanitize-filename'
import {GlobalHotKeys} from "react-hotkeys";
import {addNotification} from "../state/notifications.state";
import styled from "styled-components";

const DownloadButton: React.FC = () => {
    const documentState = useDocumentState();

    const getDownloadSuccessMessage = () => <p><StyledMessageTitle>Your Mockup is downloaded.</StyledMessageTitle>
        <StyledMessageText>Loved the experience?</StyledMessageText>
        <Button variant="primary"
                onClick={() => window.open('https://senja.io/p/mockmagic/r/Kp1C4h', '_blank')?.focus()}
                style={{margin: '0 auto'}}>Leave a short testimonial</Button>
    </p>;

    // Sanitize document name
    function sanitizeFileName(name: string) {
        // Sanitize by stripping invalid characters for different systems
        const saveFileName = sanitize(name)

        // Remove spaces
        const fileNameNoSpaces = saveFileName.replace(/\s/g, '-')

        return fileNameNoSpaces
    }

    function handleExportToClipboard() {
        exportToBlob((b) => {
            if (null === b) {
                return
            }

            navigator.clipboard.write(
                [
                    new ClipboardItem({
                        'image/png': Promise.resolve(b)
                    })]).then(() => {
                addNotification({
                    message:
                        'Mockup added to clipboard.',
                })
            }).catch(() => {
                // console.log('error at clipboard write', error)
                addNotification({
                    message:
                        'Copying to clipboard isn’t supported in this browser',
                })
            })
        });
    }

    function handleExportToFile() {
        exportToBlob((b) => {
            if (null === b) {
                return
            }

            const a = document.createElement('a')
            document.body.append(a)
            a.download = sanitizeFileName(documentState.name!) + '.png' // Sanitize file name and force .png format
            a.href = URL.createObjectURL(b)
            a.click()
            a.remove()
        })

    }

    function handleDownloadClick() {
        handleExportToFile()
        addNotification({
            message: getDownloadSuccessMessage(),
            autohide: true,
            delay: 100

        })
    }

    const exportToBlob = (callback: BlobCallback) => {
        if (documentState.renderer === undefined) {
            return
        }

        documentState.renderer.renderer.plugins.extract
            .canvas(documentState.renderer.stage.getChildByName('viewport'))
            .toBlob(callback, 'image/png')
    }

    const hotKeyHandlers = {
        export_to_clipboard: async (event: any) => {
            event.preventDefault()
            handleExportToClipboard()
        },
        export_to_file: (event: any) => {
            event.preventDefault()
            handleExportToFile()
            addNotification({
                message: getDownloadSuccessMessage(),
            })
        }
    }

    return (
        <GlobalHotKeys handlers={hotKeyHandlers} allowChanges={true}>
            <Button whileTap={{scale: 0.95}} onClick={handleDownloadClick} id='mockup-download'>
                Download mockup
            </Button>
        </GlobalHotKeys>
    )
}

const StyledMessageTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 1.8rem;
  color: ${({theme}) => theme.color.text2};
  margin: 0 0 .5rem 0;
`

const StyledMessageText = styled.span`
  display: block;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({theme}) => theme.color.text3};
  margin: 1rem 0;
`

export default DownloadButton
